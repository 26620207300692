import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {jwtDecode} from 'jwt-decode'; // Import jwt-decode to decode JWT tokens

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthLoading, setIsAuthLoading] = useState(true); // New state to track if we are checking the token
  const [errormessage, setErrorMessage] = useState("An error occurred");

  const showErrorToast = (message) => toast.error(message);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        // If token has expired, log out the user
        if (decodedToken.exp < currentTime) {
          logout(); // Log out if token is expired
          setIsAuthLoading(false); // Set loading to false after the check
        } else {
          // If token is valid, verify it with the server
          axios.get('http://localhost:5000/protected', {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(() => {
            setIsAuthenticated(true);
            setIsAuthLoading(false); // Set loading to false after success
          })
          .catch(() => {
            setIsAuthenticated(false);
            localStorage.removeItem('token');
            setIsAuthLoading(false); // Set loading to false after failure
          });
        }
      } catch (err) {
        // In case of an error decoding the token
        setIsAuthenticated(false);
        setIsAuthLoading(false);
      }
    } else {
      setIsAuthLoading(false); // No token found, finish loading
    }
  }, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post('http://localhost:5000/login', { username, password });
      localStorage.setItem('token', response.data.token); // Store token in localStorage
      setIsAuthenticated(true);
      return true;
    } catch (err) {
      if (err.response) {
        setErrorMessage(err.response.data);
        showErrorToast(err.response.data);
      } else {
        setErrorMessage('An error occurred. Please try again.');
        showErrorToast('An error occurred. Please try again.');
      }
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAuthLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
