import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ShowCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [pageSize, setPageSize] = useState(1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await fetch('http://localhost:5000/api/message-templates');
            const data = await response.json();
            
          } catch (error) {
            console.log('Failed to fetch data');
          }
      };
  
    fetchData();
    const loadCustomers = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/customers`);
        const customerArray = response.data;
        setCustomers(customerArray);
        // setTotalPages(Math.ceil(response.data.totalCustomers / pageSize));
      } catch (error) {
        toast.error("Failed to load customers");
      }
    };
    loadCustomers();
  }, [currentPage, pageSize]);

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setSelectedCustomers(prevSelected => 
      prevSelected.includes(id) 
      ? prevSelected.filter(customerId => customerId !== id) 
      : [...prevSelected, id]
    );
  };

  // Handle delete selected customers
  const handleDelete = async () => {
    if (selectedCustomers.length === 0) {
      toast.warning("No customers selected");
      return;
    }

    try {
      await axios.post('http://localhost:5000/api/customers/delete', { ids: selectedCustomers });
      setCustomers(customers.filter(customer => !selectedCustomers.includes(customer.id)));
      setSelectedCustomers([]);
      toast.success("Customers deleted successfully");
    } catch (error) {
      toast.error("Failed to delete customers");
    }
  };

  // Handle page size change (dropdown)
  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when page size changes
  };

  return (
    <div>
      <h2>Customer List</h2>
      { customers&& customers.length > 0 ? (
        <>
          <table className="customer-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setSelectedCustomers(
                        e.target.checked ? customers.map((cust) => cust.id) : []
                      )
                    }
                    checked={selectedCustomers.length === customers.length && customers.length > 0}
                  />
                </th>
                <th>Name</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedCustomers.includes(customer.id)}
                      onChange={() => handleCheckboxChange(customer.id)}
                    />
                  </td>
                  <td>{customer.name}</td>
                  <td>{customer.mobile}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Dropdown */}
          <div className="pagination-controls">
            <label htmlFor="pageSize">Customers per page: </label>
            <select id="pageSize" value={pageSize}>
              <option value={1000}>1000</option>
              <option value={5000}>5000</option>
              <option value={10000}>10000</option>
              <option value={20000}>20000</option>
            </select>
          </div>

          {/* Pagination Controls */}
          {/* <div className="pagination">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div> */}

          {/* Delete Button */}
          <button className="delete-btn" onClick={handleDelete}>
            Delete Selected Customers
          </button>
        </>
      ) : (
        <span>There are no customers to show</span>
      )}
      <ToastContainer />
    </div>
  );
};

export default ShowCustomers;
