import './styles/styles.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import CustomerPages from './pages/CustomerPages';
import CreateCustomers from './pages/components/customers/CreateCustomers';
import LoginPage from './pages/LoginPage';
import ProtectedRoute from './pages/components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';
import { useState } from 'react';
import MainLayout from './pages/components/MainLayout';
import ShowCustomers from './pages/components/customers/ShowCustomers';

const IdealLogout = ({ onLogout }) => {
  const handleIdle = () => {
    onLogout();
  }

  const { reset } = useIdleTimer({
    timeout: 10 * 60 * 1000,
    onIdle: handleIdle
  });

  return null;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <AuthProvider>
      <IdleTimerProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path='/' element={<Navigate to="/login" replace />} />
            <Route path='/ShowCustomers' element={<MainLayout><ProtectedRoute><ShowCustomers /></ProtectedRoute></MainLayout>} />
            <Route path='/CreateCustomers' element={<MainLayout><ProtectedRoute><CreateCustomers /></ProtectedRoute></MainLayout>} />
            
          </Routes>
        </BrowserRouter>
      </IdleTimerProvider>
    </AuthProvider>
  );
}

export default App;
