import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";

const CreateCustomers = () =>{
    const [name, setName] = useState();
    const [number, setNumber] = useState();
    const [stores,setStores] = useState([]);
    const [selectedstores,setSelectedStores] = useState('');
    const [city,setCity] = useState([]);
    const [selectedcity,setSelectedCity] = useState('');
    const [errormessage, setErrorMessage] = useState("error");
    const showErrorToast = () => toast.error(errormessage);

    useEffect(()=>{
                const fetchcity = async() =>{
            try{
                const response = await fetch('http://localhost:5000/api/city');
                const city = await response.json();
                setCity(city);
            }catch(ers){
                console.error(ers);
            }
        }

        
        fetchcity();
    },[])

    const filterstores = async(cityid) =>{
        if(cityid !== null || cityid !== "select"){
            console.log(cityid)
            setSelectedCity(cityid);
            try{
                const response = await axios.post('http://localhost:5000/filterstore', { cityid });
                const stores = await response.data;
                setStores(stores);
            }catch(ers){
                console.error(ers);
            }
        }
        else{
            setSelectedCity(cityid);
            setErrorMessage("Watch Out!\nPlease Select Valid City.");
            showErrorToast();
        }
    }
    const handleSubmit = async(e) =>{
        e.preventDefault();
        const type = "Regular";
        if(name != null && number != null && selectedcity != null && selectedstores != null){
            try{
                    const response = await axios.post('http://localhost:5000/createcustomer', { name,number,selectedcity,selectedstores,type});
                    if(response.status === 201){
                        toast.success("Data Saved Succesfully");
                        window.location.reload();
                    }
                    else{
                        console.log(response);
                    }
                    
                }catch(ers){
                    console.error(ers);
                }
        }
        else{
            await setErrorMessage("Please Add Proper Data");
            showErrorToast();
        }
    }
    return(
        <div className="innerpage-main-container">
            <form onSubmit={handleSubmit}>
                <div className="input-div">
                    <label>Name</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} required/>
                </div>
                <div className="input-div">
                    <label>Whatsapp Number</label>
                    <input type="number" value={number} onChange={(e) => setNumber(e.target.value)}/>
                </div>
                <div className="input-div">
                    <label>City</label>
                    <select id="city" value={selectedcity} onChange={(e) => { filterstores(e.target.value)}} required>
                        <option>select</option>
                        {
                            city.length > 0 ? (
                                city.map((ct) => (
                                    <option key={ct.id} value={ct.id}>{ct.Name}</option>
                                ))
                            ) : ('')
                        }
                    </select>
                </div>
                <div className="input-div">
                    <label>Store</label>
                    <select id="stores" value={selectedstores} onChange={(e) => {setSelectedStores(e.target.value)}}>
                        <option>select</option>
                        {
                            stores.length > 0 ? (stores.map((store) => (
                                <option key={store.id} value={store.id}>{store.name}</option>
                            ))) : ('')
                        }
                    </select>
                </div>
                <div className="input-div">
                    <label>Type</label>
                    <select>
                        <option>select</option>
                    </select>
                </div>
                <button type="submit">Submit</button>
            </form>
            <ToastContainer/>
        </div>
    )
}

export default CreateCustomers;