// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <nav>
        <ul>
          <li><Link to="/ShowCustomers">Customers</Link>
            <ul>
                <li><Link to="/CreateCustomers">Create Customers</Link></li>
            </ul>
        </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
